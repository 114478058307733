.skillRatingCount {
	position: relative;
	/* left: 40%; */
	top: 100%;
	box-sizing: content-box;
	font-size: large;
	opacity: 0.5;
	/* background-color: #00ffff; */
  /* display: inline-block; */
}

.SkilltableCell {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  height: max-content;
}

.dateRangeContainer {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	align-items: center;
}

.dateRangeSelector {
	display: flex;
	gap: 10px;
}